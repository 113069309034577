import api from '../library/axios';

export default {
  getBroadcast(params) {
    return api({
      method: 'get',
      url: '/api/backoffice/monitoring/template/',
      params,
    });
  },
  getApproximateCharges(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/charges/${channel}`,
      params,
    });
  },
  getAllConversations(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/channel-sessions/${channel}/all`,
      params,
    });
  },
  getFreeConversations(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/channel-sessions/${channel}/free`,
      params,
    });
  },
  getPaidConversations(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/channel-sessions/${channel}/paid`,
      params,
    });
  },
  getAllConversationCategory(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/chart/category/${channel}/all`,
      params,
    });
  },
  getFreeConversationCategory(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/chart/category/${channel}/free`,
      params,
    });
  },
  getPaidConversationCategory(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/chart/category/${channel}/paid`,
      params,
    });
  },
  getBroadcastTemplate(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/broadcast/${channel}/list`,
      params,
    });
  },
  getBroadcastTemplateTotal(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/broadcast/${channel}/total`,
      params,
    });
  },
  getMessageStatus(channel, params) {
    return api({
      method: 'get',
      url: `/api/backoffice/monitoring/chart/messages/${channel}`,
      params,
    });
  },
  workspaceStats({
    workspace_id,
    company_id,
  }) {
    return api({
      method: 'get',
      url: `/api/monitoring/stats/${workspace_id}`,
      params: {
        company_id,
      },
    });
  },
};
