<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row mb-3">
              <div class="col-10 col-sm-10">
                <h4>Sales Monitoring</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-6">
                <el-select filterable :filter-method="handlerFilterWorkspace" class="w-100" size="small" placeholder="Select Workspace" v-model="filter.workspace_ids" multiple>
                  <el-option v-for="(item) in workspace_list_filter" :value="item.id" :label="item.label" :key="item.id">{{ item.label }}</el-option>
                </el-select> &nbsp;
              </div>
              <div class="col-4 col-sm-4">
                <el-date-picker
                  v-model="filter.date_range"
                  type="daterange"
                  size="small"
                  :picker-options="date_picker_options"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker> &nbsp;
              </div>
              <div class="col-2 col-sm-2">
                <el-button :loading="loading.table" @click="page = 1; searchMonitoring();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-body" v-loading="loading.approximateCharges">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="d-flex justify-content-between align-items-center mr-4">
                          <h4 class="float-left">Approximate Charges</h4>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-4 mr-4">
                          <h4 class="float-left font-weight-light">Revenue</h4>
                          <h4 class="text-primary">Rp. {{ charges.all.toLocaleString('id-ID') }}</h4>
                          <!-- <h4 class="text-primary">Rp. 3.242.555.999</h4> -->
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-2 mb-2 mr-4">
                          <h4 class="float-left font-weight-light">Daily Average</h4>
                          <h4 class="text-primary">Rp. {{ charges.average.toLocaleString('id-ID') }}</h4>
                          <!-- <h4 class="text-primary">Rp. 3.242.555.999</h4> -->
                        </div>
                      </div>
                      <!-- <div class="col-md-1"></div> -->
                      <div class="col-md-5">
                        <b-list-group>
                          <b-list-group-item v-for="(item, key) in charges.category" :key="key" class="d-flex justify-content-between align-items-center p-2">
                            {{ pricing_category[key] }}
                            <h5 class="text-primary">Rp. {{ item.toLocaleString('id-ID') }}</h5>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body" v-loading="loading.messageStatus" style="height: 200px;">
                    <div class="card-head">
                      <h5>Total Messages</h5>
                    </div>
                    <div v-if="!isEmpty(messages_chart_by_status.series)">
                      <apexchart ref="count_status_chart" :options="messages_chart_by_status" :series="messages_chart_by_status.series" height="180px"></apexchart>
                    </div>
                    <el-empty v-else class="mt-n4" :image-size="75" description="No data"></el-empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row chart-by mt-n4">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body" v-loading="loading.allConversations" style="height: 250px;">
                    <div class="table-responsive">
                      <b-list-group>
                        <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                          <h5 class="float-left">All Conversations</h5>
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ this.total.allConversations.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, key) in allConversationList" :key="key" class="d-flex justify-content-between align-items-center p-2">
                          {{ pricing_category[key] }}
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ item.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body" v-loading="loading.freeConversations" style="height: 250px;">
                    <div class="table-responsive">
                      <b-list-group>
                        <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                          <h5 class="float-left">Free Conversations</h5>
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ this.total.freeConversations.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, key) in freeConversationList" :key="key" class="d-flex justify-content-between align-items-center p-2">
                          {{ free_conversation[key] }}
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ item.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body" v-loading="loading.paidConversations" style="height: 250px;">
                    <div class="table-responsive">
                      <b-list-group>
                        <b-list-group-item class="d-flex justify-content-between align-items-center p-2">
                          <h5 class="float-left">Paid Conversations</h5>
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ this.total.paidConversations.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, key) in paidConversationList" :key="key" class="d-flex justify-content-between align-items-center p-2">
                          {{ pricing_category[key] }}
                          <b-badge variant="primary" pill style="padding: 3px 5px; font-size: 12px;">{{ item.toLocaleString('id-ID') }}</b-badge>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row chart-by">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body" v-loading="loading.chartConversations" style="min-height: 500px;">
                    <div class="card-head">
                      <h5>All Conversations Category</h5>
                    </div>
                    <div v-if="!isEmpty(all_conversation_count_by_category.series)">
                      <apexchart ref="all_conversation_category_count" :options="all_conversation_count_by_category" :series="all_conversation_count_by_category.series" height="250px"></apexchart>
                    </div>
                    <el-empty v-else description="No data"></el-empty>
                    <div class="card-head">
                      <h5>Paid Conversations Category</h5>
                    </div>
                    <div v-if="!isEmpty(paid_conversation_count_by_category.series)">
                      <apexchart ref="paid_conversation_category_count" :options="paid_conversation_count_by_category" :series="paid_conversation_count_by_category.series" height="250px"></apexchart>
                    </div>
                    <el-empty v-else description="No data"></el-empty>
                    <div  v-if="!isEmpty(paid_conversation_charges_by_category.series)">
                      <apexchart ref="paid_conversation_category_charges" :options="paid_conversation_charges_by_category" :series="paid_conversation_charges_by_category.series" height="250px"></apexchart>
                    </div>
                    <el-empty v-else description="No data"></el-empty>
                    <div class="card-head">
                      <h5>Free Conversations Category</h5>
                    </div>
                    <div v-if="!isEmpty(free_conversation_count_by_category.series)">
                      <apexchart ref="free_conversation_category_count" :options="free_conversation_count_by_category" :series="free_conversation_count_by_category.series" height="250px"></apexchart>
                    </div>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row chart-by mb-5">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body" v-loading="loading.messageStatus" style="min-height: 300px;">
                    <div class="card-head">
                      <h5>Status Message By Date</h5>
                    </div>
                    <div v-if="!isEmpty(messages_chart_by_time.series)">
                      <apexchart ref="message_status_chart" :options="messages_chart_by_time" :series="messages_chart_by_time.series" height="300px"></apexchart>
                    </div>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 pb-2">
              <h5>Template Used</h5>
            </div>
            <div class="table-responsive">
              <table class="table table-hover" v-loading="loading.broadcastList">
                <thead>
                  <tr>
                    <th>Template Name</th>
                    <th>Channel</th>
                    <th>Workspace Name</th>
                    <th>Total Recipient</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in broadcastList" :key="key">
                    <td>{{ item.template_name }}</td>
                    <td>{{ item.channel_name }}</td>
                    <td>{{ item.workspace_name }}</td>
                    <td>{{ item.total_recipients }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!isEmpty(this.broadcastList)">
              <el-pagination
              :page-size="10"
              layout="prev, pager, next"
              :total="this.total.broadcastList"
              @current-change="setPage"
              >
              </el-pagination>
            </div>
            <el-empty v-else description="No data"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import moment from 'moment';
import _ from 'lodash';

// import popupErrorMessages from '../../library/popup-error-messages';
import workspacesAPI from '../../api/workspaces';
import monitoringAPI from '../../api/salesMonitoring';

export default {
  name: 'salesMonitoring',
  metaInfo: {
    title: 'Sales Monitoring',
  },
  beforeCreate() {
    this.isEmpty = _.isEmpty;
  },
  data() {
    return {
      workspace_list_filter: [],
      workspace_list: [],
      broadcastList: [],
      allConversationList: {
        service_conversation: 0,
        marketing_conversation: 0,
        utility_conversation: 0,
        authentication_conversation: 0,
      },
      paidConversationList: {
        service_conversation: 0,
        marketing_conversation: 0,
        utility_conversation: 0,
        authentication_conversation: 0,
      },
      freeConversationList: {
        free_tier: 0,
        free_entry_point: 0,
      },
      charges: {
        all: 0,
        average: 0,
        category: {
          service_conversation: 0,
          marketing_conversation: 0,
          utility_conversation: 0,
          authentication_conversation: 0,
        },
      },
      total: {
        allConversations: 0,
        freeConversations: 0,
        paidConversations: 0,
        broadcastList: 0,
      },
      date_picker_options: {
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      filter: {
        workspace_ids: [],
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        page: this.$route.query.page || 1,
      },
      pricing_category: {
        user_initiated: 'User Initiated',
        business_initiated: 'Business Initiated',
        service_conversation: 'Service',
        marketing_conversation: 'Marketing',
        utility_conversation: 'Utility',
        authentication_conversation: 'Authentication',
        referral_conversion: 'Referral Conversation',
        service: 'Service',
        marketing: 'Marketing',
        utility: 'Utility',
        authentication: 'Authentication',
      },
      free_conversation: {
        free_entry_point: 'Free Entry Point',
        free_tier: 'Free Tier',
      },
      loading: {
        allConversations: false,
        freeConversations: false,
        paidConversations: false,
        chartConversations: false,
        broadcastList: false,
        messageStatus: false,
        approximateCharges: false,
      },
      messages_chart_by_time: {
        series: [],
        chart: {
          height: 250,
          type: 'area',
          // stacked: true,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: ['transparent'],
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Charges',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          // inverseOrder: false,
          shared: true,
          fixed: {
            enabled: true,
          },
        },
      },
      all_conversation_count_by_category: {
        series: [],
        chart: {
          // id: 'count',
          // group: 'conversation_category',
          height: 250,
          type: 'area',
          // stacked: true,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 1],
          curve: 'smooth',
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Messages',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          // inverseOrder: false,
          shared: true,
          y: {
            formatter: (val) => val.toLocaleString('id-ID'),
          },
        },
      },
      free_conversation_count_by_category: {
        series: [],
        chart: {
          // id: 'count',
          // group: 'conversation_category',
          height: 250,
          // stacked: true,
          type: 'area',
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 1],
          curve: 'smooth',
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Messages',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          // inverseOrder: false,
          shared: true,
          y: {
            formatter: (val) => val.toLocaleString('id-ID'),
          },
        },
      },
      paid_conversation_count_by_category: {
        series: [],
        chart: {
          id: 'count',
          group: 'conversation_category',
          height: 250,
          // stacked: true,
          type: 'area',
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 1],
          curve: 'smooth',
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Messages',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          // inverseOrder: false,
          shared: true,
          y: {
            formatter: (val) => val.toLocaleString('id-ID'),
          },
        },
      },
      paid_conversation_charges_by_category: {
        series: [],
        chart: {
          id: 'charges',
          group: 'conversation_category',
          height: 250,
          type: 'area',
          // stacked: true,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 1],
          curve: 'smooth',
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Charges',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          // inverseOrder: false,
          shared: true,
          y: {
            formatter: (val) => `Rp. ${val.toLocaleString('id-ID')}`,
          },
        },
      },
      messages_chart_by_status: {
        series: [],
        chart: {
          height: 200,
          type: 'pie',
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        labels: [],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          fixed: {
            enabled: true,
          },
        },
      },
      dataTotal: 0,
      page: 1,
      documentCount: 0,
      loaderStack: 0,
      loader: null,
      last_index: null,
      channel: 'whatsapp',
    };
  },
  computed: {
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  async mounted() {
    this.searchMonitoring();
  },
  methods: {
    setPage(val) {
      this.filter.page = val;
      this.getBroadcastList();
    },
    async searchMonitoring() {
      this.showLoader();
      this.resetValue();
      await this.getWorkspaces();
      await this.getApproximateCharges();
      await this.getMessageStatus();
      await this.getBroadcastTotal();
      await this.getConversationAll();
      await this.getConversationFree();
      await this.getConversationPaid();
      await this.getAllConversationCategory();
      await this.getPaidConversationCategory();
      await this.getFreeConversationCategory();
      await this.getBroadcastList();
      this.hideLoader();
    },
    async getWorkspaces() {
      await workspacesAPI.getWorkspaces()
        .then(async (res) => {
          this.workspace_list = res.data;
          this.workspace_list_filter = res.data;
        });
    },
    handlerFilterWorkspace(keyword) {
      console.log(keyword);
      this.timeout = setTimeout(() => {
        if (keyword) {
          this.workspace_list_filter = this.workspace_list.filter((v) => v.label.toLowerCase().includes(keyword.toLowerCase()));
        } else {
          this.workspace_list_filter = this.workspace_list.map((v) => v);
        }
      }, 500);
    },
    async getApproximateCharges() {
      this.loading.approximateCharges = true;
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getApproximateCharges(this.channel, options)
        .then((res) => {
          this.charges.all = res.data.data.charges_all;
          const days = moment(this.filter.date_range[1]).diff(moment(this.filter.date_range[0]), 'days') + 1;
          const avg = res.data.data.charges_all / days;
          this.charges.average = Math.trunc(avg);
          res.data.data.charges_by_category.forEach((v) => {
            this.charges.category[v.key] = v.charges.value;
          });
        }).catch((err) => {
          console.log(err);
          this.loading.approximateCharges = false;
          this.hideLoader();
        });
      this.loading.approximateCharges = false;
    },
    async getConversationAll() {
      this.loading.allConversations = true;
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getAllConversations(this.channel, options)
        .then((res) => {
          if (res.data.data.data_count) {
            res.data.data.data_count.forEach((v) => {
              this.allConversationList[v.key] = v.doc_count;
            });
          }
          this.total.allConversations = res.data.data.count;
        }).catch((err) => {
          console.log(err);
          this.loading.allConversations = false;
          this.hideLoader();
        });
      this.loading.allConversations = false;
    },
    async getConversationFree() {
      this.loading.freeConversations = true;
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getFreeConversations(this.channel, options)
        .then((res) => {
          this.freeConversationList = res.data.data.data_count;
          this.total.freeConversations = res.data.data.count;
        }).catch((err) => {
          console.log(err);
          this.loading.freeConversations = false;
          this.hideLoader();
        });
      this.loading.freeConversations = false;
    },
    async getConversationPaid() {
      this.loading.paidConversations = true;
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getPaidConversations(this.channel, options)
        .then((res) => {
          res.data.data.data_count.forEach((v) => {
            this.paidConversationList[v.key] = v.doc_count;
          });
          this.total.paidConversations = res.data.data.count;
        }).catch((err) => {
          console.log(err);
          this.loading.paidConversations = false;
          this.hideLoader();
        });
      this.loading.paidConversations = false;
    },
    async getBroadcastTotal() {
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      options.page = this.filter.page;
      await monitoringAPI.getBroadcastTemplateTotal(this.channel, options)
        .then((res) => {
          this.total.broadcastList = res.data.count;
          this.dataSize = Math.ceil(this.total.broadcastList / 10);
        }).catch((err) => {
          console.log(err);
          this.hideLoader();
        });
    },
    async getBroadcastList() {
      this.loading.broadcastList = true;
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      options.page = this.filter.page;
      await monitoringAPI.getBroadcastTemplate(this.channel, options)
        .then((res) => {
          this.dataSize = Math.ceil(this.total.broadcastList / 10);
          this.broadcastList = res.data.rows;
        }).catch((err) => {
          console.log(err);
          this.loading.broadcastList = false;
          this.hideLoader();
        });
      this.loading.broadcastList = false;
    },
    async getAllConversationCategory() {
      this.loading.chartConversations = true;
      const x_axis = {
        type: 'datetime',
        min: new Date(moment(this.filter.date_range[0]).format('YYYY-MM-DD')).getTime(),
        max: new Date(moment(this.filter.date_range[1]).format('YYYY-MM-DD')).getTime(),
      };
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getAllConversationCategory(this.channel, options)
        .then(async (res) => {
          this.all_conversation_count_by_category.xaxis = x_axis;
          this.all_conversation_count_by_category.series = res.data.data.count_conversation;
          if (!_.isEmpty(this.all_conversation_count_by_category.series)) {
            this.$refs.all_conversation_category_count.refresh();
          }
        }).catch((err) => {
          console.log(err);
          this.loading.chartConversations = false;
          this.hideLoader();
        });
      this.loading.chartConversations = false;
    },
    async getFreeConversationCategory() {
      this.loading.chartConversations = true;
      const x_axis = {
        type: 'datetime',
        min: new Date(moment(this.filter.date_range[0]).format('YYYY-MM-DD')).getTime(),
        max: new Date(moment(this.filter.date_range[1]).format('YYYY-MM-DD')).getTime(),
      };
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getFreeConversationCategory(this.channel, options)
        .then(async (res) => {
          this.free_conversation_count_by_category.xaxis = x_axis;
          this.free_conversation_count_by_category.series = res.data.data.count_conversation;
          if (!_.isEmpty(this.free_conversation_count_by_category.series)) {
            this.$refs.free_conversation_category_count.refresh();
          }
        }).catch((err) => {
          console.log(err);
          this.loading.chartConversations = false;
          this.hideLoader();
        });
      this.loading.chartConversations = false;
    },
    async getPaidConversationCategory() {
      this.loading.chartConversations = true;
      const x_axis = {
        type: 'datetime',
        min: new Date(moment(this.filter.date_range[0]).format('YYYY-MM-DD')).getTime(),
        max: new Date(moment(this.filter.date_range[1]).format('YYYY-MM-DD')).getTime(),
      };
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getPaidConversationCategory(this.channel, options)
        .then(async (res) => {
          this.paid_conversation_charges_by_category.xaxis = x_axis;
          this.paid_conversation_count_by_category.xaxis = x_axis;
          this.paid_conversation_charges_by_category.series = res.data.data.charges_conversation;
          this.paid_conversation_count_by_category.series = res.data.data.count_conversation;

          if (!_.isEmpty(this.paid_conversation_count_by_category.series)) {
            this.$refs.paid_conversation_category_count.refresh();
            this.$refs.paid_conversation_category_charges.refresh();
          }

          // this.$refs.paid_conversation_category_charges.updateSeries(res.data.data.charges_conversation);
          // this.$refs.paid_conversation_category_count.updateSeries(res.data.data.count_conversation);
        }).catch((err) => {
          console.log(err);
          this.loading.chartConversations = false;
          this.hideLoader();
        });
      this.loading.chartConversations = false;
    },
    async getMessageStatus() {
      this.loading.messageStatus = true;
      this.messages_chart_by_time.xaxis = {
        type: 'datetime',
        min: new Date(moment(this.filter.date_range[0]).format('YYYY-MM-DD')).getTime(),
        max: new Date(moment(this.filter.date_range[1]).format('YYYY-MM-DD')).getTime(),
      };
      this.messages_chart_by_status.series = [];
      this.messages_chart_by_status.labels = [];
      const options = {};
      options.start_date = moment(this.filter.date_range[0]).format('YYYY-MM-DD');
      options.end_date = moment(this.filter.date_range[1]).format('YYYY-MM-DD');
      options.workspace_ids = this.filter.workspace_ids;
      await monitoringAPI.getMessageStatus(this.channel, options)
        .then(async (res) => {
          res.data.data.status_messages.forEach((v) => {
            this.messages_chart_by_status.labels.push(v.key);
            this.messages_chart_by_status.series.push(v.doc_count);
          });
          this.messages_chart_by_time.series = res.data.data.data_messages;
          if (!_.isEmpty(this.messages_chart_by_time.series)) {
            this.$refs.message_status_chart.refresh();
          }
        }).catch((err) => {
          console.log(err);
          this.loading.messageStatus = false;
          this.hideLoader();
        });
      this.loading.messageStatus = false;
    },
    resetValue() {
      this.allConversationList = {
        service_conversation: 0,
        marketing_conversation: 0,
        utility_conversation: 0,
        authentication_conversation: 0,
      };
      this.paidConversationList = {
        service_conversation: 0,
        marketing_conversation: 0,
        utility_conversation: 0,
        authentication_conversation: 0,
      };
      this.freeConversationList = {
        free_tier: 0,
        free_entry_point: 0,
      };
      this.charges = {
        all: 0,
        average: 0,
        category: {
          service_conversation: 0,
          marketing_conversation: 0,
          utility_conversation: 0,
          authentication_conversation: 0,
        },
      };
      this.total = {
        allConversations: 0,
        freeConversations: 0,
        paidConversations: 0,
        broadcastList: 0,
      };
    },
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
